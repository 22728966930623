import { camelCase, startCase } from 'lodash';
import marked from 'marked';

import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { SimpleDocumentModel } from 'media/models/document';
import FileModel from 'media/models/file';
import convertResponseToModel from 'utils/responseToModel';
import { Model } from 'core/model';
import { convertKeysCase } from 'core/utils';
import { getApiUrl } from 'utils/urls';


class TransactionPriceInfoModel {
  constructor({
    finalPrice,
    isReserved,
    reservationContext,
  }) {
    this.price = parseFloat(finalPrice);
    this.isReserved = isReserved;
    this.reservationContext = reservationContext;
  }
}

/**
 * Transaction Modal File Model
 */
class TransactionModalFileModel extends FileModel {
  constructor({ basePath, ...restProps }) {
    super(restProps);
    this.basePath = basePath;
  }

  get fetchUrl() {
    // @todo the logic to construct a proper file url could be lifted up and delegated to the document model
    return `${this.basePath}files/${this.id}/download/`;
  }
}

/**
 * Transaction Modal Simple Document Model
 */
class TransactionModalSimpleDocumentModel extends SimpleDocumentModel {

  constructor({ unsignedHash, multisigTransactionId, ...restProps }) {
    super(restProps);
    const basePath = this.constructor.getBasePath(unsignedHash, multisigTransactionId);
    this.files = convertResponseToModel(
      (restProps.files || []).map((file) => ({ ...file, basePath })),
      TransactionModalFileModel,
    );
  }

  static getBasePath(unsignedHash, multisigTransactionId) {
    if (unsignedHash && multisigTransactionId) {
      throw new Error('unsignedHash and multisigTransactionId are mutually exclusive');
    }
    if (unsignedHash) {
      return `/api/v1/ethereum/transactions/${unsignedHash}/`;
    }
    if (multisigTransactionId) {
      return getApiUrl(`/multisig-transactions/${multisigTransactionId}/`);
    }
    return '';
  }

  static fromArrayResponse(data, unsignedHash, multisigTransactionId) {
    return data.map((doc) => this.fromResponse({ ...doc, unsignedHash, multisigTransactionId }));
  }
}

/**
 * Transaction Model
 *
 * Props under restProps are:
 * description, details
 *
 */
export default class TransactionModel extends Model {
  constructor({
    documents,
    legalEntity,
    technicalDetails,
    batch,
    priceInfo,
    title,
    description,
    createdAt,
    unsignedHash,
    multisigTransactionId,
    ...restProps
  }) {
    super(restProps);
    this.documents = TransactionModalSimpleDocumentModel.fromArrayResponse(documents, unsignedHash, multisigTransactionId);
    this.signingAccount = new SimpleLegalEntityModel(convertKeysCase(legalEntity, camelCase));
    this.technicalDetails = convertKeysCase(technicalDetails, camelCase);
    this.batch = batch ? convertKeysCase(batch, camelCase) : null;
    this.priceInfo = priceInfo ? new TransactionPriceInfoModel(convertKeysCase(priceInfo, camelCase)) : null;
    this.title = title ? startCase(title) : gettext('Confirm transaction');
    this.description = marked(description);
    this.createdAt = new Date(createdAt);
  }
}
