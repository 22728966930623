import { getApiUrl } from 'utils/urls';
import { signTransaction } from 'utils/signTransaction';
import axios from 'core/axios';
import {
  MultisigTransactionDetailModel,
  MultisigTransactionListItemModel,
  MultisigTransactionSigningHistoryModel,
} from 'signatories/models/multisigTransactions';
import TransactionModel from 'core/page/transactionSigning/model';
import { SetDisabled } from 'types';
import { MultisigTransactionStatus } from 'signatories/types';

const multisigTransactionsBasePath = '/multisig-transactions/';

export const getTransactions = (
  statuses: MultisigTransactionStatus[],
  noOfItems?: string,
): Promise<MultisigTransactionListItemModel[]> => {
  const urlParams = new URLSearchParams({});
  statuses.forEach((status) => { urlParams.append('status', status); });
  if (noOfItems) {
    urlParams.append('no_of_items', noOfItems);
  }

  return axios.get(getApiUrl(multisigTransactionsBasePath, urlParams))
    .then(({ data }) => MultisigTransactionListItemModel.fromResponse(data))
    .catch(console.error);
};

export const getTransactionDetail = (multisigTransactionId: number): Promise<MultisigTransactionDetailModel> => (
  axios.get(getApiUrl(`${multisigTransactionsBasePath}${multisigTransactionId}/`))
    .then(({ data }) => MultisigTransactionDetailModel.fromResponse(data))
    .catch(console.error)
);

export const getTransactionSigningHistory = (
  multisigTransactionId: number,
): Promise<MultisigTransactionSigningHistoryModel[]> => (
  axios.get(getApiUrl(`${multisigTransactionsBasePath}${multisigTransactionId}/history/`))
    .then(({ data }) => MultisigTransactionSigningHistoryModel.fromResponse(data))
    .catch(console.error)
);

export const getUnsignedTransactionDetails = (multisigTransactionId?: number): Promise<TransactionModel> => (
  axios.get(getApiUrl(`${multisigTransactionsBasePath}${multisigTransactionId}/transaction-details/`))
    .then(({ data }) => TransactionModel.fromResponse({ ...data, multisigTransactionId }))
    .catch(console.error)
);

export const confirmTransaction = (multisigTransactionId: number, setDisabled: SetDisabled) => (
  signTransaction({
    urlPath: `${multisigTransactionsBasePath}${multisigTransactionId}/confirm`,
    navigatePath: `/signatories${multisigTransactionsBasePath}${multisigTransactionId}`,
    setDisabled,
  })
);

export const revokeTransactionSignature = (multisigTransactionId: number, setDisabled: SetDisabled) => (
  signTransaction({
    urlPath: `${multisigTransactionsBasePath}${multisigTransactionId}/revoke`,
    navigatePath: `/signatories${multisigTransactionsBasePath}${multisigTransactionId}`,
    setDisabled,
  })
);
