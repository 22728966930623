import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useReducer, useState } from 'react';

import { CreateArtworksTransferView } from '@trustwise/design-system';
import { getApiUrl } from 'utils/urls';
import { getGlobalContext } from 'core/globals';
import { onEventFileDownload } from 'registeredObjects/views/actions';
import { PageContent } from 'core/page';
import { PROPERTY_TOKEN_TRANSFER } from 'businessTransactions/consts';
import axios from 'core/axios';
import BackButton from 'core/page/parts/backButton';
import useContacts from 'contacts/hooks';
import { ProvidedServices } from 'core/providedServices';
import { getAssetDetail, getObjectDetail, onTransferFeeCalculate, onTransferInitialize } from './actions';
import reducer from '../reducers';

const InitializeArtworksAssetsTransferView = () => {
  const navigate = useNavigate();

  const { portfolioId, tokenId } = useParams();
  const { onFetch: onContactsFetch } = useContacts(false, { 'service-type': ProvidedServices.ARBITER, 'only-public': 1 });
  const { onFetch: onContactsWithPortfolioFetch } = useContacts(true);
  const { activeEntity: { baseCurrency } } = getGlobalContext();

  // TODO: make context for asset and object detail
  const [state, dispatch] = useReducer(reducer, { registeredObject: {}, asset: {} });
  const { asset, registeredObject } = state;
  const { objectType, objectId } = asset;

  const objectPath = objectType && objectId && `/registered-objects/artworks/${objectType}/${objectId}/`;

  const [transferStages, setTransferStages] = useState(undefined);

  useEffect(() => {
    getAssetDetail({ tokenId, portfolioId, dispatch });
  }, [portfolioId, tokenId]);

  useEffect(() => {
    if (objectPath) {
      getObjectDetail({ objectPath, dispatch });
    }
  }, [objectPath]);

  useEffect(() => {
    axios.get(getApiUrl(`/assets/transfers/${portfolioId}/${tokenId}/`, { type: PROPERTY_TOKEN_TRANSFER }))
      .then(({ data }) => setTransferStages(data))
      .catch(console.error);
  }, [portfolioId, tokenId]);

  return (
    <PageContent
      fullscreen
      pageHeading={registeredObject.title ? gettext(`Transfer of ${registeredObject.title}`) : ''}
      headerLeft={<BackButton onClick={() => navigate(`/artworks/${portfolioId}/${tokenId}/`)} />}
    >
      <CreateArtworksTransferView
        stages={transferStages}
        onTransferFeeCalculate={(amount, pricePerUnit, currency) => onTransferFeeCalculate({
          amount, pricePerUnit, currency, portfolioId, tokenId,
        })}
        arbiterActions={{
          onContactsFetch,
          onContactSearch: ({ searchString }) => onContactsFetch(searchString),
        }}
        recipientActions={{
          onContactsFetch: onContactsWithPortfolioFetch,
          onContactSearch: ({ searchString }) => onContactsWithPortfolioFetch(searchString),

        }}
        artwork={registeredObject.details}
        events={{
          event: registeredObject.lastEvent,
          onFileDownload: (fileId) => onEventFileDownload({
            obj: registeredObject.lastEvent, fileId, objectPath: `/services${objectPath}events/`,
          }),
        }}
        images={{
          media: registeredObject.media,
        }}
        objectTitle={registeredObject.title}
        amount={asset.amount}
        currency={baseCurrency}
        onSubmit={(values, actions) => onTransferInitialize({
          submitPath: `/assets/transfers/${portfolioId}/${tokenId}/`,
          navigatePath: `/assets/artworks/${portfolioId}/${tokenId}/`,
          values,
          actions,
        })}
      />
    </PageContent>
  );
};

export default InitializeArtworksAssetsTransferView;
