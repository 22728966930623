import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useReducer, useState } from 'react';

import { ArtworkAssetDetailView as ArtworkAssetDetail } from '@trustwise/design-system';
import { getGlobalContext } from 'core/globals';
import { onEventFileDownload } from 'registeredObjects/views/actions';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { getRouterLinkInteraction } from 'core/utils';
import { getAssetDetail, getObjectDetail, onTransfersFetch } from './actions';
import reducer from '../reducers';

const ArtworkAssetDetailView = () => {
  const navigate = useNavigate();

  const { portfolioId, tokenId } = useParams();
  const { activeEntity: { baseCurrency } } = getGlobalContext();

  const [activePage, setActivePage] = useState('overview');

  // TODO: make context for asset and object detail
  const [state, dispatch] = useReducer(reducer, { registeredObject: {}, asset: {} });
  const { registeredObject, asset } = state;
  const { objectType, objectId, amount, valuePerUnit, noOfUnits, marketplaces, suspendedActionsReason, transactions } = asset;

  const objectPath = objectType && objectId && `/registered-objects/artworks/${objectType}/${objectId}/`;

  useEffect(() => {
    getAssetDetail({ tokenId, portfolioId, dispatch });
  }, [portfolioId, tokenId]);

  useEffect(() => {
    if (objectPath) {
      getObjectDetail({ objectPath, dispatch });
    }
  }, [objectPath]);

  useEffect(() => {
    onTransfersFetch({ tokenId, portfolioId, dispatch });
  }, [portfolioId, tokenId]);

  const objectValue = registeredObject.coOwnershipQuotas ? registeredObject.coOwnershipQuotas.quotas.referenceValue : undefined;

  return (
    <PageContent
      fullscreen
      pageHeading={registeredObject.title || ''}
      headerLeft={<BackButton onClick={() => navigate('/artworks/')} />}
    >
      <ArtworkAssetDetail
        activePage={activePage}
        onTabSwitch={(tabId) => setActivePage(tabId)}
        sellOrderCreateInteraction={marketplaces && marketplaces.length && (
          getRouterLinkInteraction(`/artworks/${portfolioId}/${tokenId}/sell/`)
        )}
        transferInteraction={getRouterLinkInteraction(`/artworks/${portfolioId}/${tokenId}/transfer/`)}
        overview={{
          coOwnershipUnits: {
            totalNoOfUnits: noOfUnits,
            amount,
          },
          totalValue: {
            valuePerUnit: Number(valuePerUnit),
            objectValue,
            currency: baseCurrency,
          },
          transactions: {
            transactions: transactions ? transactions.slice(-2) : undefined,
            onTransactionsView: () => setActivePage('transactions'),
          },
          registrationDetails: registeredObject.registrationDetails,
          objectCard: {
            ...registeredObject.details,
            media: registeredObject.media,
            onDetailsView: () => setActivePage('details'),
          },
        }}
        objectDetails={{
          artwork: registeredObject.details,
          images: {
            media: registeredObject.media,
          },
          events: {
            event: registeredObject.lastEvent,
            onFileDownload: (fileId) => onEventFileDownload({
              obj: registeredObject.lastEvent, fileId, objectPath: `/services${objectPath}events/`,
            }),
          },
        }}
        transactions={transactions}
        actionsDisabledReason={suspendedActionsReason}
      />
    </PageContent>
  );
};

export default ArtworkAssetDetailView;
