import { convertKeysToCamelCase, convertNestedKeysToCamelCase } from 'core/utils';
import { SimpleDocumentModel, FileModel } from 'media/models';
import convertResponseToModel from 'utils/responseToModel';
import { getApiUrl } from 'utils/urls';
import { Model } from 'core/model';
import { LegalEntityAvatarModel } from '../../contacts/models/legalEntity';

/**
 * Registered Object List Item Model
 *
 * Used only for the curator/manager.
 * If this is ever used for investors then the thumbnail (url) must be set accordingly.
 *
 * Props under restProps are:
 * 'title', 'author', 'hasCoOwnership'
 */
export class RegisteredObjectListItemModel {
  constructor({
    id, listPath, registeredAt, ...restProps
  }) {
    this.id = id;
    this.registeredAt = registeredAt ? new Date(registeredAt) : null;
    this.thumbnail = getApiUrl(`${listPath}/${id}/thumbnail/`);
    Object.entries(restProps).forEach(([fieldName, fieldValue]) => {
      this[fieldName] = fieldValue;
    });
  }
}

/**
 * Registered Object Event Model
 *
 * Props under restProps are:
 * 'id', 'title', 'currency', 'objectValue, 'id', 'eventType'
 */
export class RegisteredObjectEventModel {
  constructor({
    files, createdAt, date, ...restProps
  }) {
    this.files = convertResponseToModel(files || [], FileModel);
    this.date = new Date(date);
    this.dateAdded = new Date(createdAt);
    Object.entries(restProps).forEach(([fieldName, fieldValue]) => {
      this[fieldName] = fieldValue;
    });
  }
}

/**
 * Registered Object Location Model
 *
 * Props under restProps are:
 * 'id', 'jurisdiction', 'location', 'responsibleEntity'
 */
export class RegisteredObjectLocationModel {
  constructor({
    files, date, ...restProps
  }) {
    this.files = convertResponseToModel(files || [], FileModel);
    this.date = new Date(date);
    Object.entries(restProps).forEach(([fieldName, fieldValue]) => {
      this[fieldName] = fieldValue;
    });
  }
}

/**
 * Registered Object Co-owner Model
 *
 * Props under restProps are:
 * 'id', 'name', 'noOfUnits',
 */
export class RegisteredObjectCoOwner {
  constructor({
    avatar, ...restProps
  }) {
    this.avatar = new LegalEntityAvatarModel(convertKeysToCamelCase(avatar));
    Object.entries(restProps).forEach(([fieldName, fieldValue]) => {
      this[fieldName] = fieldValue;
    });
  }
}


/**
 * Registered Object File Model
 */
class RegisteredObjectFileModel extends FileModel {
  constructor({ basePath, relatedObjectId, ...restProps }) {
    super(restProps);
    this.basePath = basePath;
    this.relatedObjectId = relatedObjectId;
  }

  get fetchUrl() {
    // @todo the logic to construct a proper file url could be lifted up and delegated to the document model
    return getApiUrl(`${this.basePath}/${this.relatedObjectId}/media/${this.id}/download/`);
  }
}

/**
 * Registered Object Simple Document Model
 */
class RegisteredObjectSimpleDocumentModel extends SimpleDocumentModel {

  constructor({ basePath, relatedObjectId, ...restProps }) {
    super(restProps);
    this.files = convertResponseToModel(
      (restProps.files || []).map((file) => ({ ...file, basePath, relatedObjectId })),
      RegisteredObjectFileModel,
    );
  }

  static fromArrayResponse(data, basePath, relatedObjectId) {
    return data.map((doc) => this.fromResponse({ ...doc, basePath, relatedObjectId }));
  }
}

/**
 * Registered Object Detail Model
 *
 * Props under restProps are:
 * 'author', 'blockchainId', 'creationDate', 'dimensions, 'id', 'hasCoOwnership', 'longDesc',
 * 'shortDesc', 'status', 'technique', 'title'
 */
export class RegisteredObjectDetailModel extends Model {
  constructor({
    id, catalog, registeredAt, registrant, manager, media, objectType,
    lastEvent, lastLocation, coOwners = [], coOwnership, asCurator,
    ...restProps
  }) {
    super(restProps);
    this.id = id;
    this.registeredAt = registeredAt ? new Date(registeredAt) : null;
    this.catalog = convertKeysToCamelCase(catalog);
    this.registrant = convertNestedKeysToCamelCase(registrant);
    this.manager = convertNestedKeysToCamelCase(manager);
    this.lastEvent = lastEvent ? new RegisteredObjectEventModel(convertKeysToCamelCase(lastEvent)) : {};
    this.lastLocation = lastLocation ? new RegisteredObjectLocationModel(convertKeysToCamelCase(lastLocation)) : {};

    const sharedPath = `${this.catalog.catalogType}/${objectType}`;
    const basePath = asCurator ? `/services/registered-objects/${sharedPath}` : `/registered-objects/${sharedPath}`;

    this.media = RegisteredObjectSimpleDocumentModel.fromArrayResponse(media, basePath, id);
    this._coOwners = coOwners.map((coOwner) => (new RegisteredObjectCoOwner(convertKeysToCamelCase(coOwner))));
    this._coOwnership = RegisteredObjectDetailModel.coOwnershipFromResponse(coOwnership);
    this.thumbnail = getApiUrl(`${basePath}/${id}/thumbnail/`);
    Object.entries(restProps).forEach(([fieldName, fieldValue]) => {
      this[fieldName] = fieldValue;
    });
  }

  static coOwnershipFromResponse(coOwnership) {
    return coOwnership ? {
      ...convertNestedKeysToCamelCase(coOwnership),
      agreements: SimpleDocumentModel.fromArrayResponse(coOwnership.agreements),
    } : {};
  }

  get details() {
    const detailsValues = {
      details: {
        author: this.author,
        title: this.title,
        creationDate: this.creationDate,
        technique: this.technique,
        dimensions: this.dimensions,
        shortDesc: this.shortDesc,
        longDesc: this.longDesc,
      },
      catalogType: this.catalog.catalogType,
    };

    return detailsValues;
  }

  get registrationDetails() {
    return ({
      registeredBy: this.registrant ? this.registrant.name : null,
      currentManager: this.manager.name,
      dateTime: new Date(this.registeredAt),
      objectId: this.blockchainId,
      catalogAddress: this.catalog.address,
    });
  }

  get coOwnership() { return this._coOwnership; }

  set coOwnership(coOwnership) {
    this.coOwnershipFromResponse(coOwnership);
  }

  get coOwnershipQuotas() {
    const quotas = this.coOwnership;
    if (quotas.referenceValue) {
      quotas.referenceValue = Number(quotas.referenceValue);
    }

    // @todo add agreements when implemented
    return {
      quotas,
      noOfCoOwners: this.coOwnership.noOfCoOwners,
      jurisdiction: this.coOwnership.jurisdiction,
      agreements: this.coOwnership.agreements,
    };
  }

}
