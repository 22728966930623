import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ObjectListView } from '@trustwise/design-system';
import { ASC_SORTING_ORDER } from 'core/const';
import { getApiUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import axios from 'core/axios';
import BackButton from 'core/page/parts/backButton';
import convertResponseToModel from 'utils/responseToModel';
import { getRouterLinkInteraction } from 'core/utils';
import { RegisteredObjectListItemModel } from '../models/registeredObject';
import { onObjectsSort } from './actions';

const RegisteredObjectsListView = () => {
  const navigate = useNavigate();
  const { catalogType, objectType } = useParams();

  const [objects, setObjects] = useState(undefined);

  const basePath = `/${catalogType}/${objectType}/`;
  const listPath = `/services/registered-objects${basePath}`;

  const pageTitles = {
    painting: gettext('Paintings'),
  };

  const setConvertedObjects = useCallback(
    (data) => setObjects(
      convertResponseToModel(
        data.map((obj) => ({ ...obj, listPath })),
        RegisteredObjectListItemModel,
      ),
    ),
    [listPath],
  );

  useEffect(() => {
    axios.get(getApiUrl(listPath, { order: ASC_SORTING_ORDER, order_by: 'title' }))
      .then(({ data }) => setConvertedObjects(data))
      .catch(console.error);
  }, [listPath, setConvertedObjects]);

  return (
    <PageContent
      fullscreen
      pageHeading={pageTitles[objectType]}
      headerLeft={<BackButton href={`/${catalogType}/`} useRouterLink />}
    >
      <ObjectListView
        objectType={objectType}
        catalogType={catalogType}
        onRegisterNew={() => navigate(`${basePath}register/`)}
        objects={objects}
        onSort={(column, order) => onObjectsSort(listPath, column, order, setConvertedObjects)}
        onObjectItemClick={(id) => getRouterLinkInteraction(`${basePath}${id}/`)}
      />
    </PageContent>
  );
};

export default RegisteredObjectsListView;
