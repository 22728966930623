import { useEffect, useState } from 'react';

import { ArtworksAssetsListView as ArtworksAssetsList } from '@trustwise/design-system';
import { ASC_SORTING_ORDER } from 'core/const';
import { getChartData } from 'investments/utils';
import { convertKeysToCamelCase, getRouterLinkInteraction } from 'core/utils';
import { getGlobalContext } from 'core/globals';
import { getUrl, getApiUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import axios from 'core/axios';
import BackButton from 'core/page/parts/backButton';
import { onObjectsSort } from './actions';


const ArtworksAssetsListView = () => {
  const listPath = '/assets/artworks/';
  const [objects, setObjects] = useState();
  const { activeEntity: { baseCurrency } } = getGlobalContext();

  const setConvertedObjects = (data) => setObjects(data.map(
    (item) => {
      const thumbnail = getApiUrl(`${listPath}${item.portfolio_id}/${item.id}/thumbnail/`);
      return convertKeysToCamelCase({ ...item, thumbnail });
    },
  ));

  useEffect(() => {
    axios.get(getApiUrl(listPath, { order: ASC_SORTING_ORDER, order_by: 'title' }))
      .then(({ data: { results } }) => setConvertedObjects(results))
      .catch(console.error);
  }, []);

  let chartData;
  if (objects) {
    chartData = getChartData(objects);
  }

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Artwork Investments')}
      headerLeft={<BackButton href={getUrl('/')} />}
    >
      <ArtworksAssetsList
        objects={objects}
        chartData={chartData}
        currency={baseCurrency}
        onSort={(column, order) => onObjectsSort(listPath, column, order, setConvertedObjects)}
        onObjectItemClick={(tokenId, portfolioId) => getRouterLinkInteraction(`${portfolioId}/${tokenId}/`)}
      />
    </PageContent>
  );
};

export default ArtworksAssetsListView;
