import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { getGlobalContext } from 'core/globals';
import { Model } from 'core/model';
import { getApiUrl } from 'utils/urls';

const {
  activeEntity: { id: activeEntityId },
} = getGlobalContext();

/**
 * Market Listing Model
 *
 * Props under restProps are:
 * 'id', 'currency', 'name', 'objectType', 'objectId'
 */
export class MarketsListingModel extends Model {
  constructor({
    highestBidPrice,
    lowestAskPrice,
    volBid,
    volAsk,
    listedSince,
    totalSupply,
    referencePrice,
    ...restProps
  }) {
    super(restProps);
    this.listedSince = new Date(listedSince);
    this.volBid = Number(volBid || 0);
    this.volAsk = Number(volAsk);
    this.bidPrice = Number(highestBidPrice);
    this.askPrice = Number(lowestAskPrice);
    this.amount = Number(totalSupply);
    this.referencePrice = Number(referencePrice);
  }
}

/**
 * Market Listing Model
 *
 * Props under restProps are:
 * 'id', 'currency', 'listedSince', 'lowestAskPrice', 'name', 'objectId', 'objectType', 'totalSupply', 'volAsk
 */
export class ArtworkListingModel extends MarketsListingModel {
  constructor({ author, referencePrice, ...restProps }) {
    super(restProps);
    this.artist = author;
    this.referencePrice = Number(referencePrice);
    const thumbnailPath = `/markets/artworks/${restProps.id}/thumbnail/`;
    this.thumbnail = getApiUrl(thumbnailPath);
    this.thumbnailLarge = getApiUrl(thumbnailPath, { size: 'large' });
  }
}

/**
 * Market Listing Order Model
 *
 * Props under restProps are:
 * 'id', 'currency', 'orderTypeContract', 'iban', 'payment_method', 'arbiterId', 'portfolioId', 'createdById'
 */
export class MarketsListingOrderModel extends Model {
  constructor({ amount, validUntil, pricePerUnit, arbiter, ...restProps }) {
    super(restProps);
    this.askPrice = Number(pricePerUnit);
    this.validityDate = new Date(validUntil);
    this.units = Number(amount);
    this.arbiter = arbiter ? new SimpleLegalEntityModel(arbiter) : undefined;
  }

  get isOrderCreator() {
    return this.createdById === activeEntityId;
  }

}
