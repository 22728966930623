import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { ArtworksMarketDetailView as ArtworksMarketDetailViewDS } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import { useMarketsListing, useMarketsListingDispatch } from 'markets/context';
import BackButton from 'core/page/parts/backButton';
import { fetchOrders, onFileDownload } from './actions';

const ArtworksMarketDetailView = () => {
  const navigate = useNavigate();
  const { tokenId, marketType } = useParams();

  const {
    listing,
    objectPath,
    orders,
    registeredObject: { title, details, lastEvent, media },
  } = useMarketsListing();
  const dispatch = useMarketsListingDispatch();

  useEffect(() => {
    fetchOrders({ tokenId, dispatch, queryArgs: { page_size: 3 } });
  }, [tokenId, dispatch]);

  const onTabSwitch = (tab) => {
    if (tab === 'orders') {
      dispatch({ type: 'resetOrders' });
      fetchOrders({ tokenId, dispatch });
    }
  };

  return (
    <PageContent
      fullscreen
      pageHeading={title || ''}
      headerLeft={<BackButton onClick={() => navigate(`/${marketType}/`)} />}
    >
      <ArtworksMarketDetailViewDS
        registeredObject={{
          artwork: details,
          events: {
            event: lastEvent,
            onFileDownload: (fileId) => onFileDownload({
              obj: lastEvent, fileId, objectPath: `/services${objectPath}events/`,
            }),
          },
          images: { media },
        }}
        ordersDetails={{
          orders,
          onSort: (column, order) => fetchOrders({ tokenId, queryArgs: { order, order_by: column }, dispatch }),
          onBuy: (id) => navigate(`orders/${id}/`),
        }}
        listingDetails={{ ...listing, media }}
        onTabSwitch={onTabSwitch}
      />
    </PageContent>
  );
};

export default ArtworksMarketDetailView;
