import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { SimpleDocumentModel, FileModel } from 'media/models';
import { getGlobalContext } from 'core/globals';
import convertResponseToModel from 'utils/responseToModel';
import { getApiUrl } from 'utils/urls';

const {
  activeEntity: { id: activeEntityId },
} = getGlobalContext();

/**
 * Utility Token Model
 *
 * Props under restProps are: id, name, unit, address, status
 */
export class UtilityTokenIssuerModel {
  constructor({
    utilityTokenType, totalSupply, ...restProps
  }) {
    this.type = utilityTokenType;
    this.totalAmount = Number(totalSupply);
    Object.entries(restProps).forEach(([fieldName, fieldValue]) => {
      this[fieldName] = fieldValue;
    });
  }
}

/**
 * Utility Token Transaction File Model
 */
class UtilityTokenTransactionFileModel extends FileModel {
  constructor({ basePath, ...restProps }) {
    super(restProps);
    this.basePath = basePath;
  }

  get fetchUrl() {
    // @todo the logic to construct a proper file url could be lifted up and delegated to the document model
    return getApiUrl(`${this.basePath}files/${this.id}/download/`);
  }
}


/**
 * Utility Token Transaction Simple Document Model
 */
class UtilityTokenTransactionSimpleDocumentModel extends SimpleDocumentModel {

  constructor({ basePath, ...restProps }) {
    super(restProps);
    this.files = convertResponseToModel(
      (restProps.files || []).map((file) => ({ ...file, basePath })),
      UtilityTokenTransactionFileModel,
    );
  }

  static fromArrayResponse(data, basePath) {
    return data.map((doc) => this.fromResponse({ ...doc, basePath }));
  }
}


/**
 * Utility Token Transactions Model
 */
export class UtilityTokenTransactionModel {
  constructor({
    eventType, dateTime, sender, recipient, amount, documents, basePath,
  }) {
    this.id = Number(
      `${new Date(dateTime).getTime()}${sender ? sender.id : 0}${recipient ? recipient.id : 0}`,
    );
    this.date = new Date(dateTime);
    this._amount = Number(amount);
    this.entity = new SimpleLegalEntityModel(sender || recipient);
    this.documents = UtilityTokenTransactionSimpleDocumentModel.fromArrayResponse(documents, basePath);
    this.eventType = eventType;
    this.recipient = recipient;
  }

  get amount() {
    if (this.isRecipient || this.eventType === 'mint') {
      return this._amount;
    }
    return this._amount * -1;
  }

  get isRecipient() {
    return !!this.recipient && this.recipient.id === activeEntityId;
  }
}
